import Rails from "@rails/ujs";
import "../../assets/stylesheets/application.scss";
import "maplibre-gl/dist/maplibre-gl.css";
import * as Tracking from "../src/common/tracking";
import ClipboardJS from "clipboard";
import "@hotwired/turbo-rails";

import * as SvelteImporter from "../src/svelteImporter.js";
import * as CustomSvelteImporter from "../src/common/svelteImporter.js";

import "@fontsource/mansalva";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/900.css";

SvelteImporter.createEventListeners(
  "common",
  CustomSvelteImporter.customRequire
);

window.addEventListener("turbo:load", function () {
  var clipboard = new ClipboardJS(".clipboard-copy");

  clipboard.on("success", function (e) {
    e.trigger.querySelector(".checkmark").classList.remove("invisible");
    e.trigger.querySelector(".text").innerHTML = "Copié";
  });
});

Rails.start();

// loosing the ugly fight against turbo specific cases
// https://github.com/ambethia/recaptcha/issues/217
window.addEventListener("turbo:before-cache", function () {
  if (document.querySelector(".g-recaptcha")) {
    document.querySelector(".g-recaptcha").innerHTML = "";
  }
});

window.track = Tracking.track;
window.dispatchEvent(new Event("toploc:loaded"));

// deal with iOS (and maybe other mobile devices) url bar
const appHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("resize", appHeight);
appHeight();

const addMenuListener = () => {
  const sidebarOpenBtn = document.querySelector("#sidebar-menu-open");
  if (sidebarOpenBtn) {
    sidebarOpenBtn.addEventListener("click", () => {
      document
        .querySelector("#sidebar-container")
        .classList.remove("-translate-x-full");
    });

    document.addEventListener("click", (e) => {
      if (document.querySelector("#sidebar-menu-open")?.contains(e.target))
        return;

      var sidebar = document.getElementById("sidebar-container");

      if (
        !sidebar.classList.contains("-translate-x-full") &&
        !sidebar.contains(e.target)
      ) {
        document
          .querySelector("#sidebar-container")
          .classList.add("-translate-x-full");
      }
    });
  }
};

window.addEventListener("turbo:load", () => {
  addMenuListener();
});

window.addEventListener("turbo:render", () => {
  addMenuListener();
});
